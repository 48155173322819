<template>
  <div>
    <content-background-cover
      :background-color='eventMainThemeColorBg'
      class='z-0 main-image-background'
      :top-offset='0'
      style='background-color: var(--darkColor)' />
    <div class='flex flex-row justify-center items-center ' style='height: calc(100vh - 24rem);'>
      <div class='text-center px-16 py-24 border rounded-lg shadow-md bg-white mx-4 lg:mx-auto'>
        <img :src='eventConfigLogoImageUrl' class='h-10 inline-block mb-8' />
        <h1 class='text-lg uppercase font-semibold tracking-tight' :style='themeTextColorStyle'>{{title}}</h1>
        <slot>
          <p class='text-gray-600'>{{message}}</p>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'

export default {
  name: 'ComingSoon',
  components: {
    ContentBackgroundCover,
  },
  props: {
    title: {
      type: String,
      default: 'Coming Soon',
    },
    message: {
      type: String,
      default: 'This will be be available shortly. Please check back later.',
    },
  },
  computed: {
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
      'themeTextColorStyle',
      'eventMainThemeColorBg',
    ]),
  },
}
</script>

<style lang='scss' scoped>
  .main-image-background {
    background-image: url(https://d3r2ol85dktaw0.cloudfront.net/conferences/APSR2022/visual_day.png); 
    background-repeat: no-repeat; 
    background-size: cover;  
    background-position: center;
    opacity: 0.5;
    background-color: --var(--lightColor);
    background-blend-mode: soft-light;
  }

</style>
