<template>
  <div>
    <coming-soon :title='pageTitle' />
  </div>
</template>

<script>
import ComingSoon from '@/components/apsr2022/ComingSoon.vue'

export default {
  name: 'ComingSoonPage',
  components: {
    ComingSoon,
  },
  props: {
    pageTitle: {
      type: String,
      default: 'Coming Soon'
    },
    message: {
      type: String,
      default: 'This will be be available shortly. Please check back later.'
    },
  },
}
</script>
